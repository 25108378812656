import React from 'react';
import { Navigate, Route } from 'react-router-dom';
import { apiDocsPlugin, ApiExplorerPage } from '@backstage/plugin-api-docs';
import {
  CatalogEntityPage,
  CatalogIndexPage,
  catalogPlugin,
} from '@backstage/plugin-catalog';
import {
  CatalogImportPage,
  catalogImportPlugin,
} from '@backstage/plugin-catalog-import';
import { ScaffolderPage, scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { orgPlugin } from '@backstage/plugin-org';
import { SearchPage } from '@backstage/plugin-search';
import { TechRadarPage } from '@backstage/plugin-tech-radar';
import {
  TechDocsIndexPage,
  techdocsPlugin,
  TechDocsReaderPage,
} from '@backstage/plugin-techdocs';
import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import { ReportIssue } from '@backstage/plugin-techdocs-module-addons-contrib';
import { UserSettingsPage } from '@backstage/plugin-user-settings';
import { DevToolsPage } from '@backstage/plugin-devtools';
import { apis } from './apis';
import { decathlonSSOAuthProviderApiRef } from '@internal/plugin-decathlon-sso';
import { entityPage } from './components/catalog/EntityPage';
import { searchPage } from './components/search/SearchPage';
import { Root } from './components/Root';
import {
  AlertDisplay,
  OAuthRequestDialog,
  SignInPage,
  SignInProviderConfig,
} from '@backstage/core-components';
import { createApp } from '@backstage/app-defaults';
import { AppRouter, FlatRoutes } from '@backstage/core-app-api';
import { CatalogGraphPage } from '@backstage/plugin-catalog-graph';
import { RequirePermission } from '@backstage/plugin-permission-react';
import { catalogEntityCreatePermission } from '@backstage/plugin-catalog-common/alpha';
import {
  CapabilityMapPage,
  QuadrantPage,
  StrategicViewPage,
} from '@internal/plugin-strategic-performance';
import {
  AddComponentsToSystemPage,
  OnboardingPage,
} from '@internal/plugin-onboarding';
import { icons } from '@internal/plugin-custom-catalog';
import { customDevToolsPage } from './components/devtools/CustomDevToolsPage';
import * as plugins from './plugins';
import { setTokenCookie } from './cookieAuth';
import {
  configApiRef,
  discoveryApiRef,
  IdentityApi,
  useApi,
} from '@backstage/core-plugin-api';
import { datadogRum } from '@datadog/browser-rum';
import { customTheme } from './theme';
import { UnifiedThemeProvider, themes } from '@backstage/theme';
import CssBaseline from '@material-ui/core/CssBaseline';
import Brightness2Icon from '@material-ui/icons/Brightness2';
import BrandIconGrey from './components/Root/LogoSimpleGrey';

const oauthProvider: SignInProviderConfig = {
  id: 'oauth-auth-provider',
  title: 'Sign In',
  message: 'Sign in using OAuth2',
  apiRef: decathlonSSOAuthProviderApiRef,
};

const app = createApp({
  apis,
  plugins: Object.values(plugins),
  components: {
    SignInPage: props => {
      const discoveryApi = useApi(discoveryApiRef);

      return (
        <SignInPage
          {...props}
          auto={false}
          providers={[oauthProvider]}
          onSignInSuccess={async (identityApi: IdentityApi) => {
            await setTokenCookie(
              await discoveryApi.getBaseUrl('cookie'),
              identityApi,
            );

            const credentials = await identityApi.getCredentials();
            const backstageUserIdentity =
              await identityApi.getBackstageIdentity();

            const token = parseJwt(credentials.token!);
            datadogRum.setUser({ id: token.uuid });

            const isAdmin = backstageUserIdentity.ownershipEntityRefs.includes(
              'group:default/backstage-admins',
            );

            if (!isAdmin) datadogRum.startSessionReplayRecording();

            props.onSignInSuccess(identityApi);
          }}
        />
      );
    },
  },
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      // createComponent: scaffolderPlugin.routes.root, // Add create button in catalog page
      viewTechDoc: techdocsPlugin.routes.docRoot,
    });
    bind(apiDocsPlugin.externalRoutes, {
      registerApi: catalogImportPlugin.routes.importPage,
    });
    bind(scaffolderPlugin.externalRoutes, {
      registerComponent: catalogImportPlugin.routes.importPage,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
  },
  icons: { ...icons },
  themes: [
    {
      id: 'dechathlon',
      title: 'Decathlon',
      variant: 'light',
      icon: <BrandIconGrey />,
      Provider: ({ children }) => (
        <UnifiedThemeProvider theme={customTheme} noCssBaseline>
          <CssBaseline />
          {children}
        </UnifiedThemeProvider>
      ),
    },
    {
      id: 'dark',
      title: 'Dark',
      variant: 'dark',
      icon: <Brightness2Icon />,
      Provider: ({ children }) => (
        <UnifiedThemeProvider theme={themes.dark} children={children} />
      ),
    },
  ],
});

const routes = (
  <FlatRoutes>
    <Route path="/" element={<Navigate to="catalog" />} />
    <Route
      path="/catalog"
      element={
        <CatalogIndexPage initialKind="system" initiallySelectedFilter="all" />
      }
    />
    <Route
      path="/catalog/:namespace/:kind/:name"
      element={<CatalogEntityPage />}
    >
      {entityPage}
    </Route>
    <Route path="/docs" element={<TechDocsIndexPage />} />
    <Route
      path="/docs/:namespace/:kind/:name/*"
      element={<TechDocsReaderPage />}
    >
      <TechDocsAddons>
        <ReportIssue />
      </TechDocsAddons>
    </Route>
    <Route path="/create" element={<ScaffolderPage />} />
    <Route path="/api-docs" element={<ApiExplorerPage />} />
    <Route
      path="/tech-radar"
      element={<TechRadarPage width={1500} height={800} />}
    />
    <Route
      path="/catalog-import"
      element={
        <RequirePermission permission={catalogEntityCreatePermission}>
          <CatalogImportPage />
        </RequirePermission>
      }
    />
    <Route path="/search" element={<SearchPage />}>
      {searchPage}
    </Route>
    <Route path="/settings" element={<UserSettingsPage />} />
    <Route path="/catalog-graph" element={<CatalogGraphPage />} />
    <Route path="/systems" element={<OnboardingPage />} />
    <Route path="/quadrant" element={<QuadrantPage />} />
    <Route path="/components" element={<AddComponentsToSystemPage />} />
    <Route path="/strategic-view" element={<StrategicViewPage />} />
    <Route path="/capability-map" element={<CapabilityMapPage />} />
    <Route path="/devtools" element={<DevToolsPage />}>
      {customDevToolsPage}
    </Route>
  </FlatRoutes>
);

const RootComponent = () => {
  const config = useApi(configApiRef);

  datadogRum.init({
    applicationId: config.getString('datadogRum.applicationId'),
    clientToken: config.getString('datadogRum.clientToken'),
    site: 'datadoghq.eu',
    service: 'cerebro',
    env: config.getString('log_env'),
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 0,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    startSessionReplayRecordingManually: true,
    defaultPrivacyLevel: 'allow',
  });

  return <></>;
};

export default app.createRoot(
  <>
    <AlertDisplay />
    <OAuthRequestDialog />
    <AppRouter>
      <Root>{routes}</Root>
    </AppRouter>
    <RootComponent />
  </>,
);

function parseJwt(token: string) {
  return JSON.parse(Buffer.from(token?.split('.')[1], 'base64').toString());
}
