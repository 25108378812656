import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const deliveryMetricsPlugin = createPlugin({
  id: 'delivery-metrics',
  routes: {
    root: rootRouteRef,
  },
});

export const BenchmarkTechPerfCard = deliveryMetricsPlugin.provide(
  createRoutableExtension({
    name: 'BenchmarkTechPerfCard',
    component: () =>
      import('./components/Benchmark').then(m => m.BenchmarkTechPerfCard),
    mountPoint: rootRouteRef,
  }),
);
