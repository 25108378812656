import { Typography } from '@material-ui/core';
import React from 'react';
import { Link } from '@backstage/core-components';
import { configApiRef, useApi } from '@backstage/core-plugin-api';

interface EntityLinksEmptyStateProps {
  systemName: string;
}

export function EntityLinksEmptyState({
  systemName,
}: Readonly<EntityLinksEmptyStateProps>) {
  const config = useApi(configApiRef);

  const complementaryInformationLink = config.getString(
    'customCatalog.complementarySystemInformationDocumentationUrl',
  );
  return (
    <Typography variant="body1">
      No links defined for {systemName}. You can add links by following{' '}
      <Link to={complementaryInformationLink}>the instructions here</Link>.
    </Typography>
  );
}
