import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export function DocapiIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 17 20">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.81641 0H10.8164L16.8164 6V18C16.8164 19.1 15.9164 20 14.8164 20H2.80641C1.70641 20 0.816406 19.1 0.816406 18L0.826406 2C0.826406 0.9 1.71641 0 2.81641 0ZM2.81641 2V18H14.8164V7H9.81641V2H2.81641Z"
        fill="#3643BA"
      />
    </SvgIcon>
  );
}
