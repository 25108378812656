import React from 'react';
import { Grid } from '@material-ui/core';
import { AboutField } from '@backstage/plugin-catalog';
import { EntityRefLink } from '@backstage/plugin-catalog-react';
import { System } from '@internal/plugin-custom-catalog-node';
import { CustomEntityLinksCard } from '../CustomEntityLinksCard/CustomEntityLinksCard';

interface Props {
  contentToDisplay: string;
  system: System;
}

export function CustomSystemContentToDisplay(props: Props) {
  return props.contentToDisplay.includes('About') ? (
    <Grid container>
      <Grid item md={4}>
        <AboutField label="Domain" value="No domain">
          <EntityRefLink
            entityRef={{
              kind: 'Group',
              namespace: 'default',
              name: props.system.spec.domainName,
            }}
          >
            {props.system.spec.domainName}
          </EntityRefLink>
        </AboutField>
      </Grid>
      <Grid item md={4}>
        <AboutField label="Subdomain" value="No subdomain">
          {props.system.spec.subdomainName && (
            <EntityRefLink
              entityRef={{
                kind: 'Group',
                namespace: 'default',
                name: props.system.spec.subdomainName,
              }}
            >
              {props.system.spec.subdomainName}
            </EntityRefLink>
          )}
        </AboutField>
      </Grid>
      <Grid item md={4}>
        <AboutField label="Owner" value="No Owner">
          <EntityRefLink
            entityRef={{
              kind: 'Group',
              namespace: 'default',
              name: props.system.spec.owner,
            }}
          >
            {props.system.spec.owner}
          </EntityRefLink>
        </AboutField>
      </Grid>
      <Grid container item style={{ marginTop: '3rem' }}>
        <Grid item md={4}>
          <AboutField
            label="Business criticality"
            value={props.system.spec.businessCriticalityLevel ?? 'N/A'}
          />
        </Grid>
        <Grid item md={4}>
          <AboutField
            label="Budget"
            value={
              props.system.spec.budget
                ? `${props.system.spec.budget.toLocaleString()}€`
                : 'N/A'
            }
          />
        </Grid>
      </Grid>
    </Grid>
  ) : (
    <CustomEntityLinksCard />
  );
}
