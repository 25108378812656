import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LinkIcon from '@material-ui/icons/Link';
import Typography from '@material-ui/core/Typography';
import { Link } from '@backstage/core-components';
import { Tooltip } from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';

export type IconLinkVerticalProps = {
  color?: 'primary' | 'secondary';
  href?: string;
  icon?: React.ReactNode;
  label: string;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  tooltipText?: string;
  locked?: boolean;
};

const useIconStyles = makeStyles(
  theme => ({
    link: {
      display: 'flex',
      justifyItems: 'start',
      gridGap: 4,
      textAlign: 'left',
      width: 'max-content',
    },
    primary: {
      color: theme.palette.primary.main,
    },
    secondary: {
      color: theme.palette.secondary.main,
    },
    label: {
      letterSpacing: 1.2,
      fontSize: theme.typography.fontSize,
      fontFamily: theme.typography.fontFamily,
      fontWeight: 'bold',
      marginLeft: 10,
      color: theme.palette.type === 'light' ? '#000000' : '#ffffff',
    },
    lock: {
      color: theme.palette.text.primary,
      width: '10px',
      height: '10px',
      position: 'absolute',
      right: '-0.3em',
      top: '-0.3em',
    },
  }),
  { name: 'BackstageIconLinkVertical' },
);

export const IconLinkVertical: React.FC<IconLinkVerticalProps> = ({
  color = 'primary',
  href = '#',
  icon = <LinkIcon />,
  label,
  onClick,
  tooltipText,
  locked,
}: IconLinkVerticalProps) => {
  const classes = useIconStyles();
  return (
    <Tooltip title={tooltipText ?? ''} placement="right">
      <Link
        className={`${classes.link} ${classes[color]}`}
        to={href}
        onClick={onClick}
        role="link"
        aria-label={label}
      >
        <>
          <div style={{ position: 'relative', display: 'flex' }}>
            {locked && (
              <Tooltip title="Link automatically generated">
                <LockIcon className={classes.lock} data-testid="lock-icon" />
              </Tooltip>
            )}
            {icon}
          </div>
          <Typography
            variant="caption"
            component="span"
            className={classes.label}
          >
            {label}
          </Typography>
        </>
      </Link>
    </Tooltip>
  );
};
