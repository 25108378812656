import { FilterableSystem, SelectItem } from './types';

export interface FilterPredicate {
  match(filterableSystem: FilterableSystem): boolean;
}

export class SelectItemsPredicate implements FilterPredicate {
  private readonly selectedItemNames: string[];

  constructor(
    selectedItems: SelectItem[],
    private readonly getElementToMatch: (
      filterableSystem: FilterableSystem,
    ) => string | undefined,
  ) {
    this.selectedItemNames = selectedItems.map(selected => selected.value);
  }

  match(filterableSystem: FilterableSystem): boolean {
    const elementToMatch = this.getElementToMatch(filterableSystem);
    return (
      !this.selectedItemNames.length ||
      (!!elementToMatch && this.selectedItemNames.includes(elementToMatch))
    );
  }
}

export class BooleanPredicate implements FilterPredicate {
  constructor(
    private readonly selectedItem: boolean | undefined,
    private readonly getElementToMatch: (
      filterableSystem: FilterableSystem,
    ) => boolean | undefined,
  ) {}

  match(filterableSystem: FilterableSystem): boolean {
    const elementToMatch = this.getElementToMatch(filterableSystem);
    return (
      this.selectedItem === undefined || elementToMatch === this.selectedItem
    );
  }
}

export class TermIncludedPredicate implements FilterPredicate {
  constructor(
    private readonly searchTerm: string,
    private readonly getElementToMatch: (
      filterableSystem: FilterableSystem,
    ) => string[],
  ) {}

  match(filterableSystem: FilterableSystem): boolean {
    const elementsToMatch = this.getElementToMatch(filterableSystem);

    return (
      this.searchTerm === '' ||
      !!elementsToMatch.find(element =>
        element.toLowerCase().includes(this.searchTerm.toLowerCase()),
      )
    );
  }
}

export class AllMatchPredicate implements FilterPredicate {
  constructor(private readonly predicates: FilterPredicate[]) {}

  match(filterableSystem: FilterableSystem): boolean {
    return this.predicates.every(predicate =>
      predicate.match(filterableSystem),
    );
  }
}
