import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import React, { useMemo } from 'react';
import Box from '@material-ui/core/Box';
import { useApi } from '@backstage/core-plugin-api';
import {
  catalogApiRef,
  getEntityRelations,
} from '@backstage/plugin-catalog-react';
import { useAsync } from 'react-use';
import {
  CompoundEntityRef,
  GroupEntity,
  RELATION_CHILD_OF,
} from '@backstage/catalog-model';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { InputLabel, MenuItem, TextField, Typography } from '@material-ui/core';
import { FilterableSystem, SelectItem } from './types';
import { uniqBy } from 'lodash';

const useFilterStyles = makeStyles(theme => ({
  value: {
    fontWeight: 'bold',
    fontSize: 18,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    height: theme.spacing(7.5),
    justifyContent: 'space-between',
    borderBottom: `1px solid ${theme.palette.grey[500]}`,
    marginBottom: '1em',
  },
  filters: {
    backgroundColor: theme.palette.background.paper,
    marginBottom: theme.spacing(2),
    width: '100%',
  },
  filterLabels: {
    fontWeight: 'bold',
    color: theme.palette.text.primary,
    fontSize: 'small',
  },
}));

export interface SystemFiltersProps {
  selectedFilters: SelectedFilters;
  filterableSystems: FilterableSystem[];
  onFilterChange(newSelectedFilters: SelectedFilters): void;
  onReset(): void;
}

export interface SelectedFilters {
  starred?: boolean;
  domains: SelectItem[];
  subdomains: SelectItem[];
  systemNames: SelectItem[];
  businessCriticality: SelectItem[];
}

interface SelectFilterProps {
  label: string;
  selected: SelectItem[];
  options: SelectItem[];
  onChange(selectedItems: SelectItem[]): void;
}

const SelectFilter = (props: SelectFilterProps) => {
  const classes = useFilterStyles();
  const sortedOptions = [...props.options].sort((a, b) =>
    a.label.localeCompare(b.label),
  );

  return (
    <Autocomplete
      multiple
      value={props.selected}
      options={sortedOptions}
      getOptionLabel={option => option.label}
      getOptionSelected={(option, value) => option.value === value.value}
      filterSelectedOptions
      disableCloseOnSelect
      renderInput={params => (
        <>
          <InputLabel
            shrink={false}
            htmlFor={params.id}
            className={classes.filterLabels}
          >
            <Typography className={classes.filterLabels}>
              {props.label}
            </Typography>
          </InputLabel>
          <TextField
            {...params}
            variant="outlined"
            size="small"
            className={classes.filters}
            placeholder={props.selected.length ? undefined : 'All results'}
          />
        </>
      )}
      onChange={(_, selectedItems) => {
        props.onChange(selectedItems);
      }}
    />
  );
};

export const SystemFilters = ({
  selectedFilters,
  filterableSystems,
  onFilterChange,
  onReset,
}: SystemFiltersProps) => {
  const classes = useFilterStyles();
  const catalogClient = useApi(catalogApiRef);

  const { value: domainsAndSubdomains } = useAsync(async () => {
    const catalog = await catalogClient.getEntities({
      filter: {
        kind: ['Group'],
        'spec.type': ['subdomain', 'domain'],
      },
    });

    const entities = catalog.items as GroupEntity[];

    const allDomains = entities.filter(group => group.spec.type === 'domain');

    const allSubdomains = entities.filter(
      group => group.spec.type === 'subdomain',
    );

    return { allDomains, allSubdomains };
  });

  const filtersDomainOptions = useMemo(() => {
    return domainsAndSubdomains?.allDomains.map(groupToSelectItem);
  }, [domainsAndSubdomains]);

  const filterSubdomainOptions = useMemo(() => {
    if (!domainsAndSubdomains) {
      return undefined;
    }

    let subdomainsToKeep: GroupEntity[];
    const allSubDomains = domainsAndSubdomains.allSubdomains;

    const domainFilter = selectedFilters.domains;

    if (domainFilter.length) {
      subdomainsToKeep = allSubDomains.filter(currentValue =>
        isSubdomainChildOfADomain(currentValue, domainFilter),
      );
    } else {
      subdomainsToKeep = allSubDomains;
    }

    const subdomains: SelectItem[] = subdomainsToKeep.map(groupToSelectItem);

    return subdomains;
  }, [domainsAndSubdomains, selectedFilters.domains]);

  const filterSystemsOptions = useMemo(() => {
    return filterableSystems.map(system => systemToSelectItem(system));
  }, [filterableSystems]);

  const filterBusinessCriticalityOptions = useMemo(() => {
    const businessCriticalityLevels = filterableSystems
      .map(system => system.businessCriticalityLevel)
      .filter(
        (businessCriticalityLevel): businessCriticalityLevel is string =>
          !!businessCriticalityLevel,
      )
      .map(businessCriticalityLevel =>
        systemBusinessCriticalityToSelectItem(businessCriticalityLevel),
      );

    return uniqBy(businessCriticalityLevels, level => level.value);
  }, [filterableSystems]);

  const starredFilters = [
    {
      label: 'All results',
      value: 'all-results',
    },
    {
      label: 'Yes',
      value: 'Yes',
    },
    {
      label: 'No',
      value: 'No',
    },
  ];

  const starred = selectedFilters.starred;

  const starredValue = convertBooleanToYesNo(starred);

  return (
    <>
      <Box className={classes.header}>
        <Box className={classes.value}>Filters</Box>
        <Button
          color="primary"
          onClick={() => {
            onReset();
          }}
        >
          Clear all
        </Button>
      </Box>

      <InputLabel
        id="starred-label"
        shrink={false}
        htmlFor="starred-input"
        className={classes.filterLabels}
      >
        <Typography className={classes.filterLabels}>Starred</Typography>
      </InputLabel>
      <TextField
        select
        value={starredValue}
        variant="outlined"
        size="small"
        inputProps={{
          id: 'starred-input',
        }}
        className={classes.filters}
        onChange={selectedItems => {
          const selectedValue = selectedItems.target.value;
          const newSelectedFilters: SelectedFilters = {
            ...selectedFilters,
            starred: convertYesNoToBoolean(selectedValue),
          };

          onFilterChange(newSelectedFilters);
        }}
      >
        {starredFilters.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>

      <SelectFilter
        label="Domains"
        options={filtersDomainOptions ?? []}
        selected={selectedFilters.domains}
        onChange={selectedItems => {
          const newSelectedFilters = {
            ...selectedFilters,
            domains: selectedItems,
            subdomains: [],
          };

          onFilterChange(newSelectedFilters);
        }}
      />

      <SelectFilter
        label="Subdomains"
        selected={selectedFilters.subdomains}
        options={filterSubdomainOptions ?? []}
        onChange={selectedItems => {
          const newSelectedFilters = {
            ...selectedFilters,
            subdomains: selectedItems,
          };

          onFilterChange(newSelectedFilters);
        }}
      />

      <SelectFilter
        label="Systems"
        selected={selectedFilters.systemNames}
        options={filterSystemsOptions}
        onChange={selectedItems => {
          onFilterChange({
            ...selectedFilters,
            systemNames: selectedItems,
          });
        }}
      />

      <SelectFilter
        label="Business criticality"
        selected={selectedFilters.businessCriticality}
        options={filterBusinessCriticalityOptions}
        onChange={selectedItems => {
          onFilterChange({
            ...selectedFilters,
            businessCriticality: selectedItems,
          });
        }}
      />
    </>
  );
};

function convertYesNoToBoolean(selectedValue: string) {
  if (selectedValue === 'Yes') {
    return true;
  } else if (selectedValue === 'No') {
    return false;
  }
  return undefined;
}

function convertBooleanToYesNo(starred: boolean | undefined) {
  if (starred === undefined) {
    return 'all-results';
  }

  if (starred) {
    return 'Yes';
  }

  return 'No';
}

function groupToSelectItem(group: GroupEntity): SelectItem {
  return {
    value: group.metadata.name,
    label: group.metadata.title || group.metadata.name,
  };
}

function isSubdomainChildOfADomain(
  currentValue: GroupEntity,
  domains: SelectItem[],
) {
  const childOfRelations = getEntityRelations(currentValue, RELATION_CHILD_OF);

  if (childOfRelations.length === 0) {
    return false;
  }

  const domainNames = domains.map(domain => domain.value);

  const childOfRelation: CompoundEntityRef = childOfRelations[0];
  return domainNames.includes(childOfRelation.name);
}

function systemToSelectItem(system: FilterableSystem): SelectItem {
  return {
    value: system.name,
    label: system.title,
  };
}

function systemBusinessCriticalityToSelectItem(
  businessCriticalityLevel: string,
): SelectItem {
  return {
    value: businessCriticalityLevel,
    label: businessCriticalityLevel,
  };
}
