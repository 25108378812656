import React from 'react';
import { OverrideProps } from '@material-ui/core/OverridableComponent';
import { ExtendButtonBaseTypeMap } from '@material-ui/core/ButtonBase/ButtonBase';
import { IconButtonTypeMap } from '@material-ui/core/IconButton/IconButton';
import { IconButton, Tooltip } from '@material-ui/core';

export type IconButtonWithTooltipProps<C extends React.ElementType> = {
  /**
   * The component used for the root node.
   * Either a string to use an HTML element or a component.
   */
  component?: C;
} & OverrideProps<ExtendButtonBaseTypeMap<IconButtonTypeMap>, C> & {
    disabledTitle?: string;
  };

export function IconButtonWithTooltip<C extends React.ElementType>(
  props: IconButtonWithTooltipProps<C>,
) {
  const { disabled, title, disabledTitle, children, ...rest } = props;

  return (
    <Tooltip
      title={disabled ? disabledTitle : title ?? ''}
      data-testid="IconButtonWithTooltip-root"
    >
      <span>
        <IconButton
          aria-label={title}
          {...rest}
          title={undefined}
          disabled={disabled}
          classes={{
            colorSecondary: 'red',
          }}
        >
          {children}
        </IconButton>
      </span>
    </Tooltip>
  );
}
