import { makeStyles, Box } from '@material-ui/core';
import React from 'react';
import { useEntity, useRelatedEntities } from '@backstage/plugin-catalog-react';
import { System } from '@internal/plugin-custom-catalog-node';
import { Progress, ResponseErrorPanel } from '@backstage/core-components';
import {
  configApiRef,
  IconComponent,
  useApi,
  useApp,
} from '@backstage/core-plugin-api';
import { IconLinkVertical } from './IconLinkVertical';
import { EntityLinksEmptyState } from './EntityLinksEmptyState';
import LanguageIcon from '@material-ui/icons/Language';
import {
  Entity,
  EntityLink,
  RELATION_HAS_PART,
} from '@backstage/catalog-model';
import { uniqBy } from 'lodash';

const useStyles = makeStyles(theme => ({
  gridItemCard: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100% - 10px)', // for pages without content header
    marginBottom: '10px',
  },
  gridItemCardContent: {
    flex: 1,
    padding: 0,
  },
  shortCutsRow: {
    padding: theme.spacing(2, 2),
  },
  shortutsGreyRow: {
    padding: theme.spacing(2, 2),
    backgroundColor: theme.palette.type === 'light' ? '#F8F8F8' : '#6b6b6b',
  },
  links: {
    margin: theme.spacing(2, 0),
    display: 'grid',
    gridAutoFlow: 'column',
    gridAutoColumns: 'min-content',
    gridGap: theme.spacing(3),
  },
}));

type EnrichedEntityLink = EntityLink & {
  tooltipText?: string;
  locked?: boolean;
};

export function CustomEntityLinksCard() {
  const config = useApi(configApiRef);
  const quapiBaseUrl = config.getString('quapi.baseUrl');

  const classes = useStyles();
  const cardContentClass = classes.gridItemCardContent;
  const shortutsDisplay = classes.shortCutsRow;
  const shortutsDisplayGrey = classes.shortutsGreyRow;
  const { entity: system } = useEntity<System>();

  const {
    loading: loadingComponents,
    entities,
    error,
  } = useRelatedEntities(system, {
    type: RELATION_HAS_PART,
    kind: 'Component',
  });

  const loading = loadingComponents;

  const systemLinks: EnrichedEntityLink[] = system.metadata.links ?? [];
  const links = uniqBy(
    [...systemLinks, ...generateLinksFromComponents(entities, quapiBaseUrl)],
    value => value.url,
  );

  const app = useApp();

  const iconResolver = (key?: string): IconComponent =>
    key ? app.getSystemIcon(key) ?? LanguageIcon : LanguageIcon;

  return (
    <>
      {loading && <Progress />}
      {error && <ResponseErrorPanel error={error} />}
      {!loading && !error && (
        <Box className={cardContentClass}>
          {!links.length ? (
            <EntityLinksEmptyState systemName={system.metadata.title!} />
          ) : (
            links.map((e, index) => {
              const Icon = iconResolver(e.icon);
              return (
                <div
                  className={
                    index % 2 === 0 ? shortutsDisplayGrey : shortutsDisplay
                  }
                  key={e.title}
                  data-testid={`shortcut-${e.title}`}
                >
                  <IconLinkVertical
                    icon={<Icon fontSize="small" />}
                    tooltipText={e.tooltipText}
                    label={e.title!}
                    href={e.url}
                    locked={e.locked}
                  />
                </div>
              );
            })
          )}
        </Box>
      )}
    </>
  );
}

function generateLinksFromComponents(
  entities: Entity[] | undefined,
  quapiBaseUrl: string,
): EnrichedEntityLink[] {
  if (!entities) return [];

  const entitiesWithQuapiId = entities.filter(
    entity => !!entity.metadata.annotations?.['decathlon.net/quapi'],
  );

  return entitiesWithQuapiId.flatMap(entity => {
    const quapiId = entity.metadata.annotations!['decathlon.net/quapi'];

    return quapiId
      .split(',')
      .map(id => toEntityLink(id, quapiBaseUrl, entity.metadata.name));
  });
}

function toEntityLink(
  quapiId: string,
  quapiBaseUrl: string,
  componentName?: string,
): EnrichedEntityLink {
  let title = 'QUAPI console';
  if (componentName) {
    title += ` filled in ${componentName}`;
  }

  return {
    title: 'QUAPI',
    url: `${quapiBaseUrl}/apis/${quapiId}/overview`,
    icon: 'quapi',
    tooltipText: title,
    locked: true,
  };
}
