import { IconComponent } from '@backstage/core-plugin-api';
import { IconKey } from '@internal/plugin-custom-catalog-node';
import {
  DatadogIcon,
  DocapiIcon,
  DynatraceIcon,
  GraviteeIcon,
  JiraIcon,
  PagerdutyIcon,
  SlackIcon,
  StatuspalIcon,
  WikiIcon,
} from './icons';
import GitHubIcon from '@material-ui/icons/GitHub';
import { DecathlonIcon } from './icons/DecathlonIcon';

export { CustomSystemAboutCard } from './components/CustomSystemAboutCard/CustomSystemAboutCard';
export { CustomEntityLinksCard } from './components/CustomEntityLinksCard/CustomEntityLinksCard';
export { CustomHasComponentsCard } from './components/CustomHasComponentsCard/CustomHasComponentsCard';
export { CriticalityDistribution } from './components/CriticalityPortfolio/CriticalityDistribution';
export { BudgetDistribution } from './components/CriticalityPortfolio/BudgetDistribution';
export * from './icons';

export const icons: Record<IconKey, IconComponent> = {
  slack: SlackIcon,
  jira: JiraIcon,
  datadog: DatadogIcon,
  docapi: DocapiIcon,
  dynatrace: DynatraceIcon,
  gravitee: GraviteeIcon,
  pagerduty: PagerdutyIcon,
  statuspal: StatuspalIcon,
  github: GitHubIcon,
  quapi: DecathlonIcon,
  wiki: WikiIcon,
};
