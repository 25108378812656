import React from 'react';
import { InfoCard } from '@backstage/core-components';
import { PerformanceLevelsTable } from './organism/PerformanceLevelsTable';
import { useEntity } from '@backstage/plugin-catalog-react';
import { parseEntityRef } from '@backstage/catalog-model';

export function PerformanceLevelsSystemsCard() {
  const { entity: userJourney } = useEntity();

  const systemIds = userJourney.relations
    ?.map(relation => relation.targetRef)
    .map(ref => parseEntityRef(ref))
    .map(ref => ref.name);

  const hasSystems = !!systemIds?.length;

  return (
    <InfoCard title="Systems">
      {hasSystems && <PerformanceLevelsTable systemIds={systemIds} />}
      {!hasSystems && 'No system'}
    </InfoCard>
  );
}
