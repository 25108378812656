import React from 'react';
import { InfoCard, Progress } from '@backstage/core-components';
import {
  ArcElement,
  Chart as ChartJS,
  Colors,
  Legend,
  Tooltip,
} from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { useEntity } from '@backstage/plugin-catalog-react';
import { useAsync } from 'react-use';
import { useGetSystems } from './hook';

ChartJS.register(ArcElement, Tooltip, Legend, Colors);

export function CriticalityDistribution() {
  const { getSystemsByDomainOrSubdomain } = useGetSystems();
  const { entity } = useEntity();

  const { value, loading } = useAsync(async () => {
    const catalog = await getSystemsByDomainOrSubdomain(entity);

    const countByCriticalityLevel = catalog.reduce(
      (previousValue, system) => {
        const level: string = system.spec?.businessCriticalityLevel || 'N/A';

        if (previousValue.hasOwnProperty(level)) previousValue[level] += 1;
        else previousValue[level] = 1;

        return previousValue;
      },
      {} as Record<string, number>,
    );

    const nbOfSystems = catalog.length;

    const levelDistribution = Object.values(countByCriticalityLevel).map(
      nbOfSystemByCriticality => {
        return (nbOfSystemByCriticality / nbOfSystems) * 100;
      },
    );

    return {
      labels: Object.keys(countByCriticalityLevel),
      datasets: [
        {
          data: levelDistribution,
        },
      ],
    };
  }, [entity]);

  return (
    <InfoCard title="Criticality distribution" variant="gridItem">
      {loading && <Progress />}
      {!loading && value && (
        <Pie
          style={{ maxHeight: '20rem' }}
          data={value}
          options={{
            plugins: {
              legend: {
                position: 'right',
              },
            },
          }}
        />
      )}
    </InfoCard>
  );
}
