import React from 'react';
import { configApiRef, fetchApiRef, useApi } from '@backstage/core-plugin-api';
import { useEntity } from '@backstage/plugin-catalog-react';
import { SystemEntity } from '@backstage/catalog-model';
import { useAsync } from 'react-use';
import {
  InfoCard,
  Progress,
  ResponseErrorPanel,
} from '@backstage/core-components';
import { SystemPerformanceResponse } from '@internal/plugin-strategic-performance-backend';
import { CardContent, Grid, makeStyles } from '@material-ui/core';
import { AboutField } from '@backstage/plugin-catalog';
import { Level, PerformanceLevel } from '../atoms/PerformanceLevel';

const useStyles = makeStyles({
  gridItemCard: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100% - 10px)', // for pages without content header
    marginBottom: '10px',
  },
  gridItemCardContent: {
    flex: 1,
  },
});

type TechPerformanceTheme = {
  theme: string;
  level: string;
};

export function TechPerformanceSystemCard() {
  const { entity } = useEntity<SystemEntity>();
  const { fetch } = useApi(fetchApiRef);
  const config = useApi(configApiRef);

  const classes = useStyles();
  const cardClass = classes.gridItemCard;
  const cardContentClass = classes.gridItemCardContent;

  const { value, loading, error } = useAsync(async () => {
    const response = await fetch(
      `${config.getString('backend.baseUrl')}/api/strategic-performance/${
        entity.metadata.name
      }/performance`,
    );

    if (response.status === 404) {
      return undefined;
    }

    if (!response.ok) {
      const backendError = await response.json();
      throw new Error(backendError.error.message);
    }

    const data: SystemPerformanceResponse = await response.json();

    const values = data.levels
      .map(level => ({
        theme: level.theme.label,
        level: level.level?.rank,
      }))
      .filter(
        (performance): performance is TechPerformanceTheme =>
          !!performance.level,
      );

    return values;
  }, [entity]);

  if (loading) {
    return <Progress />;
  }

  if (error) {
    return <ResponseErrorPanel error={error} />;
  }

  return (
    <InfoCard
      title="Tech performance"
      cardClassName={cardClass}
      variant="gridItem"
      noPadding
    >
      <CardContent className={cardContentClass}>
        {value && (
          <Grid container spacing={7}>
            {value.map(t => {
              return (
                <AboutField label={t.theme} key={t.theme}>
                  <PerformanceLevel level={t.level.toLowerCase() as Level} />
                </AboutField>
              );
            })}
          </Grid>
        )}
        {!value && 'No performance data'}
      </CardContent>
    </InfoCard>
  );
}
