import * as React from 'react';
import { useStarredEntities } from '@backstage/plugin-catalog-react';
import { withStyles } from '@material-ui/core';
import Star from '@material-ui/icons/Star';
import StarBorder from '@material-ui/icons/StarBorder';
import { CompoundEntityRef } from '@backstage/catalog-model';

const YellowStar = withStyles({
  root: {
    // This color is not defined as part of the Design System and is hard coded in Backstage plugins
    color: '#f3ba37',
  },
})(Star);

const FavoriteIcon = ({
  entityRef,
}: {
  entityRef: CompoundEntityRef | string;
}) => {
  const { isStarredEntity } = useStarredEntities();
  const isStarred = isStarredEntity(entityRef);

  if (isStarred) {
    return <YellowStar />;
  }

  return <StarBorder />;
};

export default FavoriteIcon;
