import {
  Link,
  Progress,
  ResponseErrorPanel,
  Table,
  TableColumn,
  TableOptions,
  TableProps,
} from '@backstage/core-components';
import React, { useState } from 'react';
import {
  configApiRef,
  fetchApiRef,
  useApi,
  useRouteRef,
} from '@backstage/core-plugin-api';
import useAsync from 'react-use/lib/useAsync';
import { PerformanceLevel } from '../atoms/PerformanceLevel';
import {
  Level,
  PerformanceTheme,
  SystemLevels,
  SystemPerformanceLevels,
} from '@internal/plugin-strategic-performance-backend';
import {
  entityRouteRef,
  useStarredEntities,
} from '@backstage/plugin-catalog-react';
import {
  createStyles,
  makeStyles,
  TextField,
  Theme,
  useTheme,
} from '@material-ui/core';
import FavoriteIcon from '../atoms/FavoriteIcon';
import CallMadeIcon from '@material-ui/icons/CallMade';
import { Alert, AlertTitle } from '@material-ui/lab';
import { useDebounce } from 'use-debounce';
import { FilterableSystemsContent } from './system-filters/FilterableSystemsContent';

type FixedRowData = {
  id: string;
  name: string;
  systemRef: string;
  businessCriticalityLevel?: string;
  domain: string;
  subdomain?: string;
};

type Trend = 'up' | 'down' | 'equal';
type Performance = {
  level: Level;
  trend?: Trend;
};

type DynamicRowData = Record<string, Performance | undefined>;

export type RowData = FixedRowData & DynamicRowData;

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    tableHeader: {
      fontFamily: theme.typography.fontFamily,
      display: 'flex',
      alignItems: 'center',
    },
  }),
);

interface PerformanceLevelsTableProps {
  systemIds?: string[];
}

export const PerformanceLevelsTable = ({
  systemIds,
}: PerformanceLevelsTableProps) => {
  const classes = useStyle();
  const { fetch } = useApi(fetchApiRef);
  const config = useApi(configApiRef);

  async function getSystemLevels(requestedDate?: Date): Promise<SystemLevels> {
    const requestedDateParam = requestedDate
      ? `requested-date=${requestedDate.toISOString()}`
      : undefined;

    const systemIdsParam = systemIds
      ? `system-ids=${systemIds.join(',')}`
      : undefined;

    const params = [requestedDateParam, systemIdsParam].filter(
      (param): param is string => !!param,
    );

    const paramsAsString = params.length ? `?${params.join('&')}` : '';

    const response = await fetch(
      `${config.getString(
        'backend.baseUrl',
      )}/api/strategic-performance/performance-levels${paramsAsString}`,
    );

    if (!response.ok) {
      throw new Error(
        `Cannot get system performance levels due to ${response.status} response`,
      );
    }

    const data: SystemLevels = await response.json();

    data.systemsPerformanceLevels.sort((systemA, systemB) =>
      systemA.title.localeCompare(systemB.title),
    );

    return data;
  }

  const entityRoute = useRouteRef(entityRouteRef);
  const backstageTheme = useTheme();

  const { isStarredEntity, toggleStarredEntity } = useStarredEntities();

  const [selectedDate, setSelectedDate] = React.useState<string>('');
  const [debouncedDate] = useDebounce<string>(selectedDate, 200);
  const [filteredSystemsPerformance, setFilteredSystemsPerformance] = useState<
    SystemPerformanceLevels[]
  >([]);

  const {
    value: performancesLevelsResponse,
    loading,
    error,
  } = useAsync(async () => {
    const systemLevels = await getSystemLevels();
    setFilteredSystemsPerformance(systemLevels.systemsPerformanceLevels);
    return systemLevels;
  }, []);

  const {
    value: performancesLevelsResponsePrevious,
    loading: loadingPrevious,
    error: errorWhenFetchingPreviousLevels,
  } = useAsync(async () => {
    if (!selectedDate) {
      return undefined;
    }

    return getSystemLevels(new Date(selectedDate));
  }, [debouncedDate]);

  const performanceLevels: SystemLevels = performancesLevelsResponse ?? {
    themes: [],
    systemsPerformanceLevels: [],
  };

  const columns = createColumns(entityRoute, performanceLevels.themes);

  if (loading) {
    return <Progress />;
  }
  if (error) {
    return <ResponseErrorPanel error={error} />;
  }

  const backgroundColorsPalette = backstageTheme.palette.background;

  const tableOptions: TableOptions<RowData> = {
    actionsColumnIndex: -1,
    actionsCellStyle: { minWidth: '96px', justifyContent: 'center' },
    paging: false,
    search: true,
    sorting: true,
    toolbar: false,
    padding: 'dense',
    rowStyle: (_, index) => ({
      backgroundColor:
        index % 2 === 0
          ? backgroundColorsPalette.default
          : backgroundColorsPalette.paper,
    }),
  };

  const systems = performanceLevels.systemsPerformanceLevels;
  const previousPerformanceLevels =
    performancesLevelsResponsePrevious?.systemsPerformanceLevels ?? [];

  const rows: RowData[] = filteredSystemsPerformance.map(system =>
    toRowData(system, previousPerformanceLevels),
  );

  const actions: TableProps<RowData>['actions'] = [
    (data: RowData) => {
      const isStarred = isStarredEntity(data.systemRef);

      return {
        icon: () => <FavoriteIcon entityRef={data.systemRef} />,
        onClick: () => toggleStarredEntity(data.systemRef),
        tooltip: isStarred ? 'Remove from favorites' : 'Add to favorites',
      };
    },
  ];

  const datePicker = (
    <TextField
      data-testid="date-picker"
      type="date"
      value={selectedDate}
      InputLabelProps={{
        shrink: true,
      }}
      onChange={e => {
        setSelectedDate(e.target.value);
      }}
    />
  );

  return (
    <>
      {errorWhenFetchingPreviousLevels && (
        <Alert severity="error">
          <AlertTitle>Cannot retrieve previous data</AlertTitle>
          {errorWhenFetchingPreviousLevels.message}
        </Alert>
      )}
      <FilterableSystemsContent
        filterableSystems={systems}
        withPaddingForContent={false}
        cardHeight="min-content"
        onFilterChange={newFilteredSystems =>
          setFilteredSystemsPerformance(newFilteredSystems)
        }
        headerMiddleContent={
          <div className={classes.tableHeader}>
            <span style={{ marginRight: '0.3rem' }}>View trends between</span>
            {datePicker}
            <span style={{ marginLeft: '0.3rem' }}>and today</span>
          </div>
        }
      >
        <Table
          options={tableOptions}
          data={rows}
          columns={columns}
          actions={actions}
          isLoading={loadingPrevious}
        />
      </FilterableSystemsContent>
    </>
  );
};

function createColumns(
  entityRoute: (params: {
    name: string;
    kind: string;
    namespace: string;
  }) => string,
  themes: PerformanceTheme[],
): TableColumn<RowData>[] {
  const systemNameColumn: TableColumn<RowData> = {
    title: 'System',
    field: 'name',
    render: data => (
      <Link
        style={{ padding: 0 }}
        to={entityRoute({
          kind: 'System',
          namespace: 'default',
          name: data.id,
        })}
      >
        {data.name}
      </Link>
    ),
    sorting: true,
    defaultSort: 'asc',
    cellStyle: () => {
      return {
        highlight: true,
        fontWeight: 700,
      };
    },
  };

  const domainColumn: TableColumn<RowData> = {
    title: 'Domain',
    field: 'domain',
  };

  const subdomainColumn: TableColumn<RowData> = {
    title: 'Subdomain',
    field: 'subdomain',
    hidden: true,
  };

  const businessCriticalityColumn: TableColumn<RowData> = {
    title: 'Business criticality',
    field: 'businessCriticalityLevel',
  };

  const themesColumns: TableColumn<RowData>[] = themes.map(theme => ({
    title: theme.label,
    field: theme.id,
    customSort: (row1, row2) => {
      const toNumber = (level: Level | undefined): number => {
        return level ? levelToNumber(level) : -1;
      };

      return toNumber(row1[theme.id]?.level) - toNumber(row2[theme.id]?.level);
    },
    render: rowData => {
      const performance = rowData[theme.id];

      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {performance && <PerformanceLevel level={performance.level} />}
          {performance?.trend && (
            <TrendIcon
              style={{ width: '1rem', height: '1rem' }}
              trend={performance.trend}
            />
          )}
        </div>
      );
    },
    headerStyle: { textAlign: 'center' },
  }));

  return [
    systemNameColumn,
    domainColumn,
    subdomainColumn,
    businessCriticalityColumn,
    ...themesColumns,
  ];
}

function toRowData(
  system: SystemPerformanceLevels,
  previousSystems: SystemPerformanceLevels[],
): RowData {
  const fixedRowData: FixedRowData = {
    id: system.name,
    systemRef: system.systemRef,
    name: system.title,
    businessCriticalityLevel: system.businessCriticalityLevel,
    domain: system.domain,
    subdomain: system.subdomain,
  };

  const previous = previousSystems.find(s => s.name === system.name);

  const dynamicRowData: DynamicRowData = Object.fromEntries(
    Object.entries(system.performanceLevelByThemeId).map(([key, value]) => {
      const previousLevel = previous?.performanceLevelByThemeId[key];
      return [
        key,
        { level: value, trend: compareLevels(value, previousLevel) },
      ];
    }),
  );

  return {
    ...fixedRowData,
    ...dynamicRowData,
  } as RowData;
}

function compareLevels(
  currentLevel: Level,
  previousLevel?: Level,
): Trend | undefined {
  if (!previousLevel) {
    return undefined;
  }

  const current = levelToNumber(currentLevel);
  const previous = levelToNumber(previousLevel);

  if (current < previous) {
    return 'down';
  }

  if (current > previous) {
    return 'up';
  }

  return 'equal';
}

function levelToNumber(level: Level): number {
  const levelsInOrder: Level[] = ['low', 'medium', 'high', 'elite'];
  return levelsInOrder.indexOf(level);
}

type TrendIconProps = {
  trend: Trend;
  style?: React.CSSProperties;
};

function TrendIcon(props: TrendIconProps) {
  if (props.trend === 'up') {
    return <CallMadeIcon data-testid="up-icon" style={props.style} />;
  }

  if (props.trend === 'equal') {
    return (
      <CallMadeIcon
        data-testid="equal-icon"
        style={{ ...props.style, transform: 'rotate(45deg)' }}
      />
    );
  }

  return (
    <CallMadeIcon
      data-testid="down-icon"
      style={{ ...props.style, transform: 'rotate(90deg)' }}
    />
  );
}
