import {
  configApiRef,
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  fetchApiRef,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';
import { onboardingApiRef, OnboardingClient } from './api';
import { decathlonSSOAuthProviderApiRef } from '@internal/plugin-decathlon-sso';
import { catalogApiRef } from '@backstage/plugin-catalog-react';

export const onboardingPlugin = createPlugin({
  id: 'onboarding',
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: onboardingApiRef,
      deps: {
        fetchApi: fetchApiRef,
        config: configApiRef,
        decathlonSSOAuthProvider: decathlonSSOAuthProviderApiRef,
        catalogApi: catalogApiRef,
      },
      factory({ fetchApi, config, decathlonSSOAuthProvider, catalogApi }) {
        return new OnboardingClient({
          fetchApi,
          config,
          decathlonSSOAuthProvider,
          catalogApi,
        });
      },
    }),
  ],
});

export const OnboardingPage = onboardingPlugin.provide(
  createRoutableExtension({
    name: 'OnboardingPage',
    component: () =>
      import('./components/OnboardingPage').then(m => m.OnboardingPage),
    mountPoint: rootRouteRef,
  }),
);

export const AddComponentsToSystemPage = onboardingPlugin.provide(
  createRoutableExtension({
    name: 'AddComponentsToSystemPage',
    component: () =>
      import('./components/AddComponentsToSystemPage').then(
        m => m.AddComponentsToSystemPage,
      ),
    mountPoint: rootRouteRef,
  }),
);
