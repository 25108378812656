import React, { useState } from 'react';
import {
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { SelectedFilters, SystemFilters } from './SystemFilters';
import FilterListIcon from '@material-ui/icons/FilterList';
import { FilterableSystem } from './types';
import { useStarredEntities } from '@backstage/plugin-catalog-react';
import { stringifyEntityRef } from '@backstage/catalog-model';
import {
  AllMatchPredicate,
  BooleanPredicate,
  SelectItemsPredicate,
  TermIncludedPredicate,
} from './predicate';
import Clear from '@material-ui/icons/Clear';
import Search from '@material-ui/icons/Search';

const useFilterStyles = makeStyles(theme => ({
  header: {
    maxHeight: '8rem',
    alignItems: 'center',
    paddingTop: '0.5em',
    paddingBottom: '0.5em',
    width: '100%',
  },
  filterButton: {
    display: 'flex',
    alignItems: 'center',
  },
  searchButton: {
    float: 'right',
  },
  title: {
    fontWeight: 'bold',
    // NOTE This size comes from the strategic view
    fontSize: '18px',
  },
  inputAdornment: {
    color: theme.palette.text.primary,
  },
}));

export interface FilterableSystemsContentProps<T extends FilterableSystem> {
  filterableSystems: T[];
  onFilterChange(filteredSystems: T[]): void;
  children: React.JSX.Element;
  headerMiddleContent?: React.JSX.Element;
  withPaddingForContent?: boolean;
  cardHeight?: React.CSSProperties['height'];
}

const DEFAULT_FILTERS: SelectedFilters = {
  domains: [],
  subdomains: [],
  systemNames: [],
  businessCriticality: [],
};

export const FilterableSystemsContent = <T extends FilterableSystem>(
  props: FilterableSystemsContentProps<T>,
) => {
  const { withPaddingForContent = true } = props;
  const [showFilters, setShowFilters] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState(DEFAULT_FILTERS);
  const [searchTerm, setSearchTerm] = useState('');

  const filtersClasses = useFilterStyles();
  const { isStarredEntity } = useStarredEntities();

  const nbOfActiveFilters = calculateSelectedFiltersCount(selectedFilters);

  const onNewSelectedFilters = (newSelectedFilters: SelectedFilters) => {
    setSelectedFilters(newSelectedFilters);

    const filteredSystems = filterSystems(
      newSelectedFilters,
      searchTerm,
      isStarredEntity,
      props.filterableSystems,
    );

    props.onFilterChange(filteredSystems);
  };

  const onNewSearchTerm = (newSearchTerm: string) => {
    setSearchTerm(newSearchTerm);

    const filteredSystems = filterSystems(
      selectedFilters,
      newSearchTerm,
      isStarredEntity,
      props.filterableSystems,
    );

    props.onFilterChange(filteredSystems);
  };

  return (
    <Grid container item xs={12}>
      {showFilters && (
        <Grid item xs={3}>
          <SystemFilters
            filterableSystems={props.filterableSystems}
            selectedFilters={selectedFilters}
            onFilterChange={newSelectedFilters => {
              onNewSelectedFilters(newSelectedFilters);
            }}
            onReset={() => onNewSelectedFilters(DEFAULT_FILTERS)}
          />
        </Grid>
      )}
      <Grid item xs={showFilters ? 9 : 12}>
        <Card style={{ height: props.cardHeight ?? '100%' }}>
          <Grid container className={filtersClasses.header}>
            <Grid item md={4}>
              <div className={filtersClasses.filterButton}>
                <IconButton
                  onClick={() => setShowFilters(!showFilters)}
                  aria-label="toggle filter list"
                >
                  <FilterListIcon />
                </IconButton>
                <Typography className={filtersClasses.title}>
                  Filters ({nbOfActiveFilters})
                </Typography>
              </div>
            </Grid>
            <Grid item md={4}>
              {props.headerMiddleContent}
            </Grid>
            <Grid item md={4}>
              <Input
                className={filtersClasses.searchButton}
                placeholder="Search"
                value={searchTerm}
                onChange={event => {
                  onNewSearchTerm(event.target.value);
                }}
                startAdornment={
                  <InputAdornment position="start">
                    <Search className={filtersClasses.inputAdornment} />
                  </InputAdornment>
                }
                endAdornment={
                  <IconButton
                    size="small"
                    aria-label="clear-search"
                    onClick={() => {
                      onNewSearchTerm('');
                    }}
                  >
                    <Clear />
                  </IconButton>
                }
              />
            </Grid>
          </Grid>
          <Divider />
          <CardContent style={withPaddingForContent ? {} : { padding: 0 }}>
            {props.children}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

function filterSystems<T extends FilterableSystem>(
  newSelectedFilters: SelectedFilters,
  searchTerm: string,
  isStarredEntity: (entityOrRef: string) => boolean,
  systems: T[],
): T[] {
  const domainPredicate = new SelectItemsPredicate(
    newSelectedFilters.domains,
    filterableSystem => filterableSystem.domainName,
  );

  const subdomainPredicate = new SelectItemsPredicate(
    newSelectedFilters.subdomains,
    filterableSystem => filterableSystem.subdomainName,
  );

  const systemNamesPredicate = new SelectItemsPredicate(
    newSelectedFilters.systemNames,
    filterableSystem => filterableSystem.name,
  );

  const businessCriticalityPredicate = new SelectItemsPredicate(
    newSelectedFilters.businessCriticality,
    filterableSystem => filterableSystem.businessCriticalityLevel,
  );

  const starredPredicate = new BooleanPredicate(
    newSelectedFilters.starred,
    filterableSystem =>
      isStarredEntity(
        stringifyEntityRef({
          kind: 'System',
          name: filterableSystem.name,
        }),
      ),
  );

  const termIncludedPredicate = new TermIncludedPredicate(
    searchTerm,
    filterableSystem => {
      return [filterableSystem.title];
    },
  );

  const allMatchPredicate = new AllMatchPredicate([
    domainPredicate,
    subdomainPredicate,
    systemNamesPredicate,
    businessCriticalityPredicate,
    starredPredicate,
    termIncludedPredicate,
  ]);

  return systems.filter(system => allMatchPredicate.match(system));
}

function calculateSelectedFiltersCount(
  selectedFilters: SelectedFilters,
): number {
  return Object.values(selectedFilters).filter(filter =>
    Array.isArray(filter) ? filter.length : filter !== undefined,
  ).length;
}
