import { green } from '@mui/material/colors';
import {
  createBaseThemeOptions,
  createUnifiedTheme,
  palettes,
} from '@backstage/theme';
import headerBack from './headerBackground.svg';

const brandColor = '#3643BA';
const whiteColor = '#FFFFFF';

export const customTheme = createUnifiedTheme({
  ...createBaseThemeOptions({
    palette: {
      ...palettes.light,
    },
  }),
  components: {
    BackstageSidebar: {
      styleOverrides: {
        drawer: () => ({
          background: `${brandColor}`,
          width: 'auto!important',
        }),
        drawerOpen: () => ({
          background: `${brandColor}`,
          width: 'auto!important',
        }),
      },
    },
    BackstageSidebarItem: {
      styleOverrides: {
        selected: () => ({
          color: '#7AFFA6!important',
        }),
        root: () => ({
          color: `${whiteColor}`,
        }),
        highlightable: () => ({
          '&:hover': {
            backgroundColor: '#7AFFA6!important',
            color: `${brandColor}`,
          },
        }),
        open: () => ({
          '&:hover': {
            backgroundColor: '#7AFFA6!important',
            color: `${brandColor}!important`,
          },
        }),
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          '&.Mui-completed': {
            color: typeof green[500],
          },
          '&.Mui-active': {
            color: '#1769aa',
          },
        },
      },
    },
    BackstageHeader: {
      styleOverrides: {
        header: {
          backgroundImage: `url(${headerBack})`,
          background: '#E1E3F5',
        },
        title: {
          color: `${brandColor}`,
        },
        type: {
          color: `${brandColor}`,
        },
        subtitle: {
          color: `${brandColor}`,
        },
      },
    },
    BackstageHeaderLabel: {
      styleOverrides: {
        value: {
          color: `${brandColor}`,
        },
        label: {
          color: `${brandColor}`,
        },
      },
    },
    PluginCatalogEntityContextMenu: {
      styleOverrides: {
        button: {
          color: `${brandColor}`,
        },
      },
    },
  },
});
