import React, { useMemo, ReactNode, useState } from 'react';
import {
  Button,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
  makeStyles,
} from '@material-ui/core';
import {
  EntityApiDefinitionCard,
  EntityConsumedApisCard,
  EntityConsumingComponentsCard,
  EntityHasApisCard,
  EntityProvidedApisCard,
  EntityProvidingComponentsCard,
} from '@backstage/plugin-api-docs';
import {
  EntityAboutCard,
  EntityDependsOnComponentsCard,
  EntityDependsOnResourcesCard,
  EntityHasSystemsCard,
  EntityLayout,
  EntityOrphanWarning,
  EntityProcessingErrorsPanel,
  EntitySwitch,
  hasCatalogProcessingErrors,
  isComponentType,
  isKind,
  isOrphan,
} from '@backstage/plugin-catalog';
import {
  EntityGithubActionsContent,
  isGithubActionsAvailable,
} from '@backstage/plugin-github-actions';
import {
  EntityGroupProfileCard,
  EntityMembersListCard,
  EntityOwnershipCard,
  EntityUserProfileCard,
} from '@backstage/plugin-org';
import { EntityTechdocsContent } from '@backstage/plugin-techdocs';
import { EmptyState } from '@backstage/core-components';
import {
  Direction,
  EntityCatalogGraphCard,
} from '@backstage/plugin-catalog-graph';
import {
  RELATION_API_CONSUMED_BY,
  RELATION_API_PROVIDED_BY,
  RELATION_CONSUMES_API,
  RELATION_DEPENDENCY_OF,
  RELATION_DEPENDS_ON,
  RELATION_HAS_PART,
  RELATION_PART_OF,
  RELATION_PROVIDES_API,
  RELATION_OWNED_BY,
} from '@backstage/catalog-model';

import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import { ReportIssue } from '@backstage/plugin-techdocs-module-addons-contrib';
import { EntityAdrContent } from '@backstage/plugin-adr';
import { PerformanceLevelsSystemsCard } from '@internal/plugin-strategic-performance';
import { EntitySonarQubeCard } from '@backstage/plugin-sonarqube';
import {
  EntityGithubInsightsContent,
  EntityGithubInsightsContributorsCard,
  EntityGithubInsightsLanguagesCard,
} from '@roadiehq/backstage-plugin-github-insights';
import { BenchmarkTechPerfCard } from '@internal/plugin-delivery-metrics';
import {
  BudgetDistribution,
  CriticalityDistribution,
  CustomHasComponentsCard,
  CustomSystemAboutCard,
} from '@internal/plugin-custom-catalog';
import {
  alertApiRef,
  fetchApiRef,
  useApi,
  configApiRef,
} from '@backstage/core-plugin-api';
import { isDomainOrSubdomain } from './isDomainOrSubdomain';
import { isCompany } from './isCompany';
import DeleteIcon from '@material-ui/icons/Delete';
import Close from '@material-ui/icons/Close';
import { usePermission } from '@backstage/plugin-permission-react';
import { systemDeletePermission } from '@internal/plugin-onboarding-common';
import { decathlonSSOAuthProviderApiRef } from '@internal/plugin-decathlon-sso';
import { useEntity } from '@backstage/plugin-catalog-react';
import { System } from '@internal/plugin-custom-catalog-node';

const techdocsContent = (
  <EntityTechdocsContent>
    <TechDocsAddons>
      <ReportIssue />
    </TechDocsAddons>
  </EntityTechdocsContent>
);

const cicdContent = (
  // This is an example of how you can implement your company's logic in entity page.
  // You can for example enforce that all components of type 'service' should use GitHubActions
  <EntitySwitch>
    <EntitySwitch.Case if={isGithubActionsAvailable}>
      <EntityGithubActionsContent />
    </EntitySwitch.Case>

    <EntitySwitch.Case>
      <EmptyState
        title="No CI/CD available for this entity"
        missing="info"
        description="You need to add an annotation to your component if you want to enable CI/CD for it. You can read more about annotations in Backstage by clicking the button below."
        action={
          <Button
            variant="contained"
            color="primary"
            href="https://backstage.io/docs/features/software-catalog/well-known-annotations"
          >
            Read more
          </Button>
        }
      />
    </EntitySwitch.Case>
  </EntitySwitch>
);

const entityWarningContent = (
  <>
    <EntitySwitch>
      <EntitySwitch.Case if={isOrphan}>
        <Grid item xs={12}>
          <EntityOrphanWarning />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>

    <EntitySwitch>
      <EntitySwitch.Case if={hasCatalogProcessingErrors}>
        <Grid item xs={12}>
          <EntityProcessingErrorsPanel />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>
  </>
);

const componentOverviewContent = (
  <Grid container spacing={3} alignItems="stretch">
    {entityWarningContent}
    <Grid item md={6}>
      <EntityAboutCard variant="gridItem" />
    </Grid>
    <Grid item md={6}>
      <EntityGithubInsightsLanguagesCard />
      <EntityGithubInsightsContributorsCard />
    </Grid>
    <Grid item md={6}>
      <EntityCatalogGraphCard variant="gridItem" height={400} />
    </Grid>
    <Grid item md={6}>
      <EntitySonarQubeCard variant="gridItem" />
    </Grid>
  </Grid>
);

const defaultOverviewContent = (
  <Grid container spacing={3} alignItems="stretch">
    {entityWarningContent}
    <Grid item md={6}>
      <EntityAboutCard variant="gridItem" />
    </Grid>

    <Grid item md={6}>
      <EntityCatalogGraphCard variant="gridItem" height={400} />
    </Grid>

    {/* We preserve that code until we decide if those cards are usefull. */}
    {/* <Grid item md={4} xs={12}>
      <EntityLinksCard />
    </Grid>*/}
    {/* <Grid item md={8} xs={12}>
      <EntityHasSubcomponentsCard variant="gridItem" />
    </Grid>*/}
  </Grid>
);

const githubInsights = (
  <>
    <EntityLayout.Route path="/code-insights" title="Code Insights">
      <EntityGithubInsightsContent />
    </EntityLayout.Route>

    <EntityLayout.Route path="/dependencies" title="Dependencies">
      <Grid container spacing={3} alignItems="stretch">
        <Grid item md={6}>
          <EntityDependsOnComponentsCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityDependsOnResourcesCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/ci-cd" title="CI/CD">
      {cicdContent}
    </EntityLayout.Route>
  </>
);

const serviceEntityPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      {componentOverviewContent}
    </EntityLayout.Route>

    {githubInsights}

    <EntityLayout.Route path="/api" title="API">
      <Grid container spacing={3} alignItems="stretch">
        <Grid item md={6}>
          <EntityProvidedApisCard />
        </Grid>
        <Grid item md={6}>
          <EntityConsumedApisCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/docs" title="Docs">
      {techdocsContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/adrs" title="ADRs">
      <EntityAdrContent />
    </EntityLayout.Route>
  </EntityLayout>
);

const websiteEntityPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      {componentOverviewContent}
    </EntityLayout.Route>

    {githubInsights}

    <EntityLayout.Route path="/docs" title="Docs">
      {techdocsContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/adrs" title="ADRs">
      <EntityAdrContent />
    </EntityLayout.Route>
  </EntityLayout>
);

const defaultComponentPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      {componentOverviewContent}
    </EntityLayout.Route>
    <EntityLayout.Route path="/docs" title="Docs">
      {techdocsContent}
    </EntityLayout.Route>
    <EntityLayout.Route path="/adrs" title="ADRs">
      <EntityAdrContent />
    </EntityLayout.Route>
  </EntityLayout>
);

/**
 * NOTE: This page is designed to work on small screens such as mobile devices.
 * This is based on Material UI Grid. If breakpoints are used, each grid item must set the `xs` prop to a column size or to `true`,
 * since this does not default. If no breakpoints are used, the items will equitably share the available space.
 * https://material-ui.com/components/grid/#basic-grid.
 */

const defaultEntityPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      {defaultOverviewContent}
    </EntityLayout.Route>
    <EntityLayout.Route path="/docs" title="Docs">
      {techdocsContent}
    </EntityLayout.Route>
    <EntityLayout.Route path="/adrs" title="ADRs">
      <EntityAdrContent />
    </EntityLayout.Route>
  </EntityLayout>
);

const componentPage = (
  <EntitySwitch>
    <EntitySwitch.Case if={isComponentType('service')}>
      {serviceEntityPage}
    </EntitySwitch.Case>

    <EntitySwitch.Case if={isComponentType('website')}>
      {websiteEntityPage}
    </EntitySwitch.Case>

    <EntitySwitch.Case>{defaultComponentPage}</EntitySwitch.Case>
  </EntitySwitch>
);

const apiPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        {entityWarningContent}
        <Grid item md={6}>
          <EntityAboutCard />
        </Grid>
        <Grid item md={6} xs={12}>
          <EntityCatalogGraphCard variant="gridItem" height={400} />
        </Grid>
        <Grid container item md={12}>
          <Grid item md={6}>
            <EntityProvidingComponentsCard />
          </Grid>
          <Grid item md={6}>
            <EntityConsumingComponentsCard />
          </Grid>
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/definition" title="Definition">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <EntityApiDefinitionCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayout>
);

const userPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        {entityWarningContent}
        <Grid item xs={12} md={6}>
          <EntityUserProfileCard variant="gridItem" />
        </Grid>
        <Grid item xs={12} md={6}>
          <EntityOwnershipCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayout>
);

const groupPage = (
  <EntitySwitch>
    <EntitySwitch.Case if={isDomainOrSubdomain()}>
      <EntityLayout>
        <EntityLayout.Route path="/" title="Overview">
          <Grid container spacing={3}>
            {entityWarningContent}
            <Grid item xs={12} md={6}>
              <EntityGroupProfileCard variant="gridItem" />
            </Grid>
            <Grid item xs={12} md={6}>
              <EntityOwnershipCard
                variant="gridItem"
                relationsType="aggregated"
              />
            </Grid>
            <Grid item xs={6}>
              <CriticalityDistribution />
            </Grid>
            <Grid item xs={6}>
              <BudgetDistribution />
            </Grid>
            <Grid item xs={12}>
              <EntityMembersListCard />
            </Grid>
          </Grid>
        </EntityLayout.Route>
      </EntityLayout>
    </EntitySwitch.Case>
    <EntitySwitch.Case if={isCompany()}>
      <EntityLayout>
        <EntityLayout.Route path="/" title="Overview">
          <Grid container spacing={3}>
            {entityWarningContent}
            <Grid item xs={12} md={12}>
              <EntityGroupProfileCard variant="gridItem" />
            </Grid>
            <Grid item xs={12}>
              <EntityMembersListCard />
            </Grid>
          </Grid>
        </EntityLayout.Route>
      </EntityLayout>
    </EntitySwitch.Case>
    <EntitySwitch.Case>
      <EntityLayout>
        <EntityLayout.Route path="/" title="Overview">
          <Grid container spacing={3}>
            {entityWarningContent}
            <Grid item xs={12} md={6}>
              <EntityGroupProfileCard variant="gridItem" />
            </Grid>
            <Grid item xs={12} md={6}>
              <EntityOwnershipCard
                variant="gridItem"
                relationsType="aggregated"
              />
            </Grid>

            <Grid item xs={12}>
              <EntityMembersListCard />
            </Grid>
          </Grid>
        </EntityLayout.Route>
      </EntityLayout>
    </EntitySwitch.Case>
  </EntitySwitch>
);

const EntityLayoutWrapper = (props: { children?: ReactNode }) => {
  const { allowed } = usePermission({ permission: systemDeletePermission });
  const useStyles = makeStyles(theme => ({
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  }));
  const classes = useStyles();
  const [showConfirmationDialog, setShowConfirmationDialog] =
    useState<boolean>(false);
  const decathlonSSOAuthProvider = useApi(decathlonSSOAuthProviderApiRef);
  const alertApi = useApi(alertApiRef);
  const { fetch } = useApi(fetchApiRef);
  const config = useApi(configApiRef);
  const { entity: system } = useEntity<System>();

  const extraMenuItems = useMemo(() => {
    return [
      {
        title: 'Delete system',
        Icon: DeleteIcon,
        onClick: () => setShowConfirmationDialog(true),
      },
    ];
  }, []);

  const closeDialog = () => {
    return setShowConfirmationDialog(false);
  };

  const onDelete = async () => {
    setShowConfirmationDialog(false);
    const fedToken = await decathlonSSOAuthProvider.getAccessToken();
    const response = await fetch(
      `${config.getString('backend.baseUrl')}/api/onboarding/system/${
        system.metadata.name
      }`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'x-fed-authorization': `Bearer ${fedToken}`,
        },
      },
    );

    if (response.ok) {
      alertApi.post({
        message: 'System deleted',
        display: 'transient',
        severity: 'success',
      });
    } else {
      alertApi.post({
        message: 'Could not delete system',
        display: 'transient',
        severity: 'error',
      });
    }
  };

  return (
    <>
      <EntityLayout
        UNSTABLE_extraContextMenuItems={allowed ? extraMenuItems : undefined}
        UNSTABLE_contextMenuOptions={{ disableUnregister: 'hidden' }}
      >
        {props.children}
      </EntityLayout>
      {showConfirmationDialog && (
        <Dialog open={showConfirmationDialog} onClose={closeDialog}>
          <DialogTitle id="alert-dialog-title">
            <Grid>Confirm</Grid>
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={closeDialog}
            >
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Typography>Are you sure ?</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDialog} color="primary" variant="outlined">
              No
            </Button>
            <Button onClick={onDelete} color="primary" variant="contained">
              Yes, I'm sure!
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

const systemPage = (
  <EntityLayoutWrapper>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3} alignItems="stretch">
        {entityWarningContent}
        <Grid item md={6}>
          <CustomSystemAboutCard />
        </Grid>
        <Grid item md={6}>
          <BenchmarkTechPerfCard />
        </Grid>
        <Grid item md={12}>
          <CustomHasComponentsCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>
    <EntityLayout.Route path="/api" title="APIS">
      <Grid container spacing={3} alignItems="stretch">
        {entityWarningContent}
        <Grid item md={12}>
          <EntityHasApisCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>
    <EntityLayout.Route path="/diagram" title="Diagram">
      <EntityCatalogGraphCard
        variant="gridItem"
        direction={Direction.LEFT_RIGHT}
        title="System Diagram"
        height={590}
        relations={[
          RELATION_PART_OF,
          RELATION_HAS_PART,
          RELATION_API_CONSUMED_BY,
          RELATION_API_PROVIDED_BY,
          RELATION_CONSUMES_API,
          RELATION_PROVIDES_API,
          RELATION_DEPENDENCY_OF,
          RELATION_DEPENDS_ON,
          RELATION_OWNED_BY,
        ]}
        unidirectional={false}
      />
    </EntityLayout.Route>
  </EntityLayoutWrapper>
);

const domainPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3} alignItems="stretch">
        {entityWarningContent}
        <Grid item md={6}>
          <EntityAboutCard variant="gridItem" />
        </Grid>
        <Grid item md={6} xs={12}>
          <EntityCatalogGraphCard variant="gridItem" height={400} />
        </Grid>
        <Grid item md={6}>
          <EntityHasSystemsCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayout>
);

const userJourneyOverviewContent = (
  <Grid container spacing={3} alignItems="stretch">
    {entityWarningContent}
    <Grid item md={6}>
      <EntityAboutCard variant="gridItem" />
    </Grid>

    <Grid item md={6}>
      <EntityCatalogGraphCard variant="gridItem" height={400} />
    </Grid>
    <Grid item md={12}>
      <PerformanceLevelsSystemsCard />
    </Grid>
  </Grid>
);

const userJourneyEntityPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      {userJourneyOverviewContent}
    </EntityLayout.Route>
  </EntityLayout>
);

export const entityPage = (
  <EntitySwitch>
    <EntitySwitch.Case if={isKind('component')} children={componentPage} />
    <EntitySwitch.Case if={isKind('api')} children={apiPage} />
    <EntitySwitch.Case if={isKind('group')} children={groupPage} />
    <EntitySwitch.Case if={isKind('user')} children={userPage} />
    <EntitySwitch.Case if={isKind('system')} children={systemPage} />
    <EntitySwitch.Case if={isKind('domain')} children={domainPage} />
    <EntitySwitch.Case
      if={isKind('userJourney')}
      children={userJourneyEntityPage}
    />

    <EntitySwitch.Case>{defaultEntityPage}</EntitySwitch.Case>
  </EntitySwitch>
);
