import React from 'react';
import { makeStyles } from '@material-ui/core';
import DecathlonLogo from './logo/decathlon-logo-simple-black.svg';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 18,
  },
  path: {
    fill: '#7df3e1',
  },
});

const BrandIconGrey = () => {
  const classes = useStyles();
  return <img src={DecathlonLogo} alt="" className={classes.svg} />;
};

export default BrandIconGrey;
