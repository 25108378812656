import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { Entity } from '@backstage/catalog-model';
import { System } from '@internal/plugin-custom-catalog-node';

export function useGetSystems() {
  const catalogClient = useApi(catalogApiRef);

  return {
    getSystemsByDomainOrSubdomain: async (
      entity: Entity,
    ): Promise<System[]> => {
      let query;
      if (entity.spec?.type === 'domain') {
        query = {
          filter: {
            kind: ['system'],
            'spec.domainName': entity.metadata.name,
          },
        };
      } else {
        query = {
          filter: {
            kind: ['system'],
            'spec.subdomainName': entity.metadata.name,
          },
        };
      }

      const getEntitiesResponse = await catalogClient.getEntities(query);
      return getEntitiesResponse.items as System[];
    },
  };
}
