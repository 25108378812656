import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export function DynatraceIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 51200 9088">
      <path
        d="M17374.69-16768.765c-658.026 3472.92-1462.28 8627.467-1900.97 13855.13-767.7 9212.38-292.45 15390.52-292.45 15390.52L2203.514 24796.61s-987.04-6909.283-1498.838-14695.935C412.225 5275.144 302.548 1034.526 302.548-1524.47c0-146.226 73.113-292.458 73.113-438.684 0-182.782 219.338-1900.97 1900.97-3509.48 1827.842-1754.73 15317.397-12319.725 15098.06-11296.13"
        fill="#3a8aca"
      />
      <path
        d="M17374.69-16768.765c-658.026 3472.92-1462.28 8627.467-1900.97 13855.13 0 0-14366.927-1718.187-15171.182 1754.744 0-182.782 255.895-2303.1 1937.562-3911.608 1827.816-1754.744 15353.928-12721.86 15134.6-11698.266"
        fill="#262424"
      />
      <path
        d="M302.537-1999.7v804.255c146.226-621.47 402.128-1060.154 913.928-1754.736 1060.154-1352.612 2778.337-1718.187 3472.92-1791.3 3509.48-475.244 8700.58-1023.597 13928.243-1169.827 9248.936-292.45 15353.964 475.24 15353.964 475.24l12977.755-12319.725s-6799.614-1279.496-14549.71-2193.384c-5081.43-621.47-9541.39-950.485-12063.83-1096.714-182.782 0-1974.082-219.338-3655.705 1389.17L1837.935-5545.743C156.312-3937.232 302.537-2145.936 302.537-1999.7"
        fill="#c8d643"
      />
      <path
        d="M46474.103 13756.386c-3509.48 475.24-8700.583 1060.154-13928.243 1242.94-9248.936 292.45-15390.524-475.24-15390.524-475.24L4177.58 26880.366s6872.727 1352.612 14622.825 2229.977c4752.416 548.357 8956.478 840.812 11515.473 987.04 182.782 0 475.24-146.226 658.026-146.226s1974.082-329.015 3655.705-1937.562c1827.856-1754.736 12868.085-14366.92 11844.492-14257.25"
        fill="#63308c"
      />
      <path
        d="M46474.107 13756.386c-3509.476 475.24-8700.58 1060.154-13928.243 1242.94 0 0 987.04 14440.04-2485.88 15061.51 182.782 0 2558.995-109.67 4240.615-1718.187 1827.856-1754.744 13197.1-14695.938 12173.5-14586.27"
        fill="#262424"
      />
      <path
        d="M30827.68 30133.947c-255.895 0-511.797-36.556-804.255-36.556 658.03-109.67 1096.714-329.015 1791.3-840.812 1389.17-987.04 1827.856-2705.224 1974.082-3399.807 621.47-3472.92 1462.28-8627.467 1864.413-13855.13 731.143-9212.38 292.458-15353.964 292.458-15353.964l12977.755-12356.29s950.485 6872.73 1498.838 14659.382c329.015 5081.43 438.684 9577.95 475.24 12063.83 0 182.782 146.226 1974.082-1535.394 3582.593L34556.51 28744.81c-1754.744 1608.51-3546.036 1389.17-3728.825 1389.17"
        fill="#81b950"
      />
    </SvgIcon>
  );
}
