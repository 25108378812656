import {
  ApiRef,
  BackstageIdentityApi,
  configApiRef,
  createApiFactory,
  createApiRef,
  discoveryApiRef,
  OAuthApi,
  oauthRequestApiRef,
  OpenIdConnectApi,
  ProfileInfoApi,
  SessionApi,
} from '@backstage/core-plugin-api';
import { OAuth2 } from '@backstage/core-app-api';

export { decathlonSsoPlugin } from './plugin';

export type DecathlonSSOAuthProvider = OAuthApi &
  OpenIdConnectApi &
  ProfileInfoApi &
  BackstageIdentityApi &
  SessionApi;

export const decathlonSSOAuthProviderApiRef: ApiRef<DecathlonSSOAuthProvider> =
  createApiRef({
    id: 'internal.auth.decathlon',
  });

export const decathlonSSOAuthApiFactory = createApiFactory({
  api: decathlonSSOAuthProviderApiRef,
  deps: {
    discoveryApi: discoveryApiRef,
    oauthRequestApi: oauthRequestApiRef,
    configApi: configApiRef,
  },
  factory: ({ discoveryApi, oauthRequestApi, configApi }) =>
    OAuth2.create({
      discoveryApi,
      oauthRequestApi,
      defaultScopes: ['openid email profile'], // Set scopes here
      environment: configApi.getOptionalString('auth.environment'), // Set environment if needed here
    }),
});
