import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const strategicPerformancePlugin = createPlugin({
  id: 'strategic-performance',
  routes: {
    root: rootRouteRef,
  },
});

export const QuadrantPage = strategicPerformancePlugin.provide(
  createRoutableExtension({
    name: 'QuadrantPage',
    component: () =>
      import('./components/QuadrantPage').then(m => m.QuadrantPage),
    mountPoint: rootRouteRef,
  }),
);
export const StrategicViewPage = strategicPerformancePlugin.provide(
  createRoutableExtension({
    name: 'StrategicViewPage',
    component: () =>
      import('./components/StrategicViewPage').then(m => m.StrategicViewPage),
    mountPoint: rootRouteRef,
  }),
);

export const CapabilityMapPage = strategicPerformancePlugin.provide(
  createRoutableExtension({
    name: 'CapabilityMapPage',
    component: () =>
      import('./components/CapabilityMapPage').then(m => m.CapabilityMapPage),
    mountPoint: rootRouteRef,
  }),
);
