import Chip from '@material-ui/core/Chip';
import React from 'react';

const defaultArgs = {
  label: 'Label',
  size: 'medium',
  variant: 'default',
  icon: 'None',
};

export interface PerformanceLevelProps {
  level: Level;
}

export type Level = 'elite' | 'high' | 'medium' | 'low';

const colorByLevel: Record<Level, string> = {
  elite: '#77D598',
  high: '#FFF496',
  medium: '#FFB74D',
  low: '#EB6471',
};

export const PerformanceLevel = (args: PerformanceLevelProps) => {
  return (
    <>
      <Chip
        style={{
          color: 'black',
          opacity: 0.87,
          backgroundColor: colorByLevel[args.level],
          textTransform: 'capitalize',
          width: '6em',
          height: '2em',
          margin: 0,
        }}
        label={args.level}
      />{' '}
    </>
  );
};

PerformanceLevel.args = defaultArgs;
