import {
  ComponentEntity,
  Entity,
  RELATION_HAS_PART,
} from '@backstage/catalog-model';
import { EntityTable, useEntity } from '@backstage/plugin-catalog-react';
import { RelatedEntitiesCard } from '@backstage/plugin-catalog';
import React from 'react';
import { LinkButton, TableColumn } from '@backstage/core-components';
import { System } from '@internal/plugin-custom-catalog-node';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { Box } from '@material-ui/core';

const componentEntityColumns: TableColumn<ComponentEntity>[] = [
  EntityTable.columns.createEntityRefColumn({ defaultKind: 'component' }),
  EntityTable.columns.createOwnerColumn(),
  EntityTable.columns.createSpecTypeColumn(),
  EntityTable.columns.createSpecLifecycleColumn(),
  EntityTable.columns.createMetadataDescriptionColumn(),
];

const componentEntityHelpLink =
  'https://backstage.io/docs/features/software-catalog/descriptor-format#kind-component';

const asComponentEntities = (entities: Entity[]): ComponentEntity[] =>
  entities as ComponentEntity[];

export function CustomHasComponentsCard() {
  const { entity: system } = useEntity<System>();

  const link = (
    <Box sx={{ display: 'flex' }}>
      <span>Has components</span>
      <Box sx={{ position: 'absolute', right: 30 }}>
        <LinkButton
          to="/components"
          state={{ system: system }}
          color="primary"
          variant="outlined"
        >
          <AddCircleIcon style={{ marginRight: 5 }} />
          Add components
        </LinkButton>
      </Box>
    </Box>
  );

  return (
    <RelatedEntitiesCard
      variant="gridItem"
      title={(<>{link}</>) as any} // title is normally of type string but the actual use allows any kind of JSX component
      entityKind="Component"
      relationType={RELATION_HAS_PART}
      columns={componentEntityColumns}
      emptyMessage="No component is part of this system"
      emptyHelpLink={componentEntityHelpLink}
      asRenderableEntities={asComponentEntities}
    />
  );
}
