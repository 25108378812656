import React from 'react';
import { InfoCard, Progress } from '@backstage/core-components';
import {
  ArcElement,
  Chart as ChartJS,
  Colors,
  Legend,
  Tooltip,
} from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { EntityRefLinks, useEntity } from '@backstage/plugin-catalog-react';
import { useAsync } from 'react-use';
import { Alert } from '@material-ui/lab';
import { Entity, stringifyEntityRef } from '@backstage/catalog-model';
import { useGetSystems } from './hook';

ChartJS.register(ArcElement, Tooltip, Legend, Colors);

export function BudgetDistribution() {
  const { entity } = useEntity();
  const { getSystemsByDomainOrSubdomain } = useGetSystems();

  const { value, loading } = useAsync(async () => {
    const systemsWithWarnings: Entity[] = [];
    const catalog = await getSystemsByDomainOrSubdomain(entity);

    const totalBudgetByCriticalityLevel = catalog.reduce(
      (previousValue, system) => {
        const level: string =
          (system.spec?.businessCriticalityLevel as string) || 'N/A';

        if (system.spec?.budget) {
          const budget: number = system.spec.budget;
          if (previousValue.hasOwnProperty(level))
            previousValue[level] += budget;
          else previousValue[level] = budget;
        } else {
          systemsWithWarnings.push(system);
        }

        return previousValue;
      },
      {} as Record<string, number>,
    );

    const levelDistribution = Object.keys(totalBudgetByCriticalityLevel).map(
      level => {
        return totalBudgetByCriticalityLevel[level];
      },
    );

    const graphData = {
      labels: Object.keys(totalBudgetByCriticalityLevel),
      datasets: [
        {
          data: levelDistribution,
        },
      ],
    };

    return {
      data: graphData,
      warnings: systemsWithWarnings,
    };
  }, [entity]);

  return (
    <InfoCard title="Budget by criticality" variant="gridItem">
      {loading && <Progress />}
      {!loading && value && (
        <>
          {value.warnings.length > 0 && (
            <Alert severity="warning">
              Some products (
              <EntityRefLinks
                entityRefs={value?.warnings.map(stringifyEntityRef)}
              />
              ) have no budget specified. They do not appear in the chart below.
            </Alert>
          )}
          <Pie
            style={{ maxHeight: '20rem' }}
            data={value.data}
            options={{
              plugins: {
                legend: {
                  position: 'right',
                },
              },
            }}
          />
        </>
      )}
    </InfoCard>
  );
}
