import { Button, Grid, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  alertApiRef,
  configApiRef,
  fetchApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import { ErrorPanel, Link, Progress } from '@backstage/core-components';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import {
  ANNOTATION_EDIT_URL,
  stringifyEntityRef,
} from '@backstage/catalog-model';
import { useAsync } from 'react-use';
import { decathlonSSOAuthProviderApiRef } from '@internal/plugin-decathlon-sso';

export interface EditSystemDialogProps {
  show: boolean;
  onClose: () => void;
  systemId: string;
  systemName: string;
}

export const EditSystemForm = (props: EditSystemDialogProps) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    getValues,
  } = useForm({
    defaultValues: {
      systemName: props.systemName,
    },
  });

  const [error, setError] = useState<Error>();

  const { fetch } = useApi(fetchApiRef);
  const config = useApi(configApiRef);
  const alertApi = useApi(alertApiRef);
  const catalogApi = useApi(catalogApiRef);
  const decathlonSSOAuthProvider = useApi(decathlonSSOAuthProviderApiRef);

  const { value: complementaryInformation, loading } = useAsync(async () => {
    return await catalogApi.getEntityByRef(
      stringifyEntityRef({
        kind: 'ComplementarySystemInformation',
        name: props.systemId,
      }),
    );
  });

  if (loading) {
    return <Progress />;
  }

  const complementaryInformationLink = config.getString(
    'customCatalog.complementarySystemInformationDocumentationUrl',
  );

  const complementaryInformationEditUrl =
    complementaryInformation?.metadata.annotations?.[ANNOTATION_EDIT_URL];

  const closeDialog = () => {
    props.onClose();
  };

  const onConfirmDialog = async () => {
    const fedToken = await decathlonSSOAuthProvider.getAccessToken();

    const response = await fetch(
      `${config.getString('backend.baseUrl')}/api/onboarding/system/${
        props.systemId
      }`,
      {
        method: 'PUT',
        body: JSON.stringify({
          entityName: getValues('systemName'),
        }),
        headers: {
          'Content-Type': 'application/json',
          'x-fed-authorization': `Bearer ${fedToken}`,
        },
      },
    );

    if (response.ok) {
      alertApi.post({
        message:
          'Your information is saved! Refresh of your system is scheduled and its new name will be visible in few minutes.',
        display: 'permanent',
        severity: 'success',
      });

      closeDialog();
    } else {
      setError(
        new Error(
          `Could not save due to ${
            response.status
          } error: ${await response.text()}`,
        ),
      );
    }
  };

  return (
    <Grid container>
      <Grid item md={12}>
        {error && <ErrorPanel error={error} />}
        <TextField
          label="System name"
          helperText="Required*"
          style={{ width: '100%' }}
          error={!!errors.systemName}
          {...register('systemName', {
            required: true,
          })}
        />
      </Grid>
      <Grid item md={12}>
        {!complementaryInformationEditUrl && (
          <>
            If you want to update the business criticality or the budget, please
            follow{' '}
            <Link to={complementaryInformationLink}>the instructions here</Link>
            .
          </>
        )}
        {complementaryInformationEditUrl && (
          <>
            If you want to update the business criticality or the budget, please
            complete or update{' '}
            <Link to={complementaryInformationEditUrl}>
              your complementary information here
            </Link>
            . (Reminder:{' '}
            <Link to={complementaryInformationLink}>instructions are here</Link>
            ).
          </>
        )}
      </Grid>
      <Grid container justifyContent="flex-end">
        <Grid item>
          <Button onClick={closeDialog} color="primary" variant="outlined">
            Cancel
          </Button>
        </Grid>
        <Grid item>
          <Button
            onClick={handleSubmit(onConfirmDialog)}
            color="primary"
            variant="contained"
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
