import { CardContent, makeStyles, Box } from '@material-ui/core';
import React, { useState } from 'react';
import { useEntity, catalogApiRef } from '@backstage/plugin-catalog-react';
import { System } from '@internal/plugin-custom-catalog-node';
import { InfoCard, Link } from '@backstage/core-components';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import { EditSystemForm } from './EditSystemForm';
import { IconButtonWithTooltip } from '../IconButtonWithTooltip/IconButtonWithTooltip';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import { blue } from '@material-ui/core/colors';
import { CustomSystemContentToDisplay } from './CustomSystemContentToDisplay';
import DescriptionIcon from '@material-ui/icons/Description';
import { useAsync } from 'react-use';
import {
  stringifyEntityRef,
  ANNOTATION_EDIT_URL,
} from '@backstage/catalog-model';

const useStyles = makeStyles(theme => ({
  gridItemCard: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100% - 10px)', // for pages without content header
    marginBottom: '10px',
  },
  gridItemCardContent: {
    flex: 1,
  },
  gridItemCardContentPadding: {
    padding: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  tabActive: {
    color: theme.palette.type === 'light' ? blue[900] : '',
    cursor: 'pointer',
  },
  tabInactive: {
    color:
      theme.palette.type === 'light'
        ? theme.palette.grey[400]
        : theme.palette.grey[500],
    cursor: 'pointer',
  },
  tabBorder: {
    backgroundColor: theme.palette.type === 'light' ? '#0d47a1' : '#ffffff',
  },
}));

export function CustomSystemAboutCard() {
  const classes = useStyles();
  const cardClass = classes.gridItemCard;
  const cardContentClass = classes.gridItemCardContent;
  const cardContentClassPadding = classes.gridItemCardContentPadding;
  const { entity: system } = useEntity<System>();
  const [editing, setEditing] = useState(false);
  const config = useApi(configApiRef);
  const [aboutOrShortcutsTab, setAboutOrShortcutsTab] =
    useState<string>('About');
  const catalogApi = useApi(catalogApiRef);

  const complementaryInformationLink = config.getString(
    'customCatalog.complementarySystemInformationDocumentationUrl',
  );

  const { value: complementarySystemInformation } = useAsync(async () => {
    return await catalogApi.getEntityByRef(
      stringifyEntityRef({
        kind: 'ComplementarySystemInformation',
        name: system.metadata.name,
      }),
    );
  });

  const isActiveTab = (tab: string) => {
    if (tab === aboutOrShortcutsTab) {
      return classes.tabActive;
    }

    return classes.tabInactive;
  };

  return (
    <InfoCard
      title={
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: 200,
            position: 'relative',
          }}
        >
          <Box
            className={isActiveTab('About')}
            onClick={() => setAboutOrShortcutsTab('About')}
          >
            About
          </Box>
          <Box
            className={isActiveTab('Shortcuts')}
            data-testid="shortcuts-tab"
            onClick={() => setAboutOrShortcutsTab('Shortcuts')}
          >
            Shortcuts
          </Box>
          <Box
            sx={{
              position: 'absolute',
              bottom: '-5px',
              left: `${aboutOrShortcutsTab.includes('About') ? '0' : '90px'}`,
            }}
          >
            <span
              className={classes.tabBorder}
              style={{
                display: 'flex',
                height: '2px',
                width: `${
                  aboutOrShortcutsTab.includes('About') ? '70px' : '110px'
                }`,
              }}
            />
          </Box>
        </Box>
      }
      headerProps={{
        action: aboutOrShortcutsTab.includes('About') ? (
          <IconButtonWithTooltip
            color="primary"
            title={editing ? 'Close form' : 'Edit system data'}
            onClick={() => setEditing(!editing)}
          >
            {editing ? <CloseIcon /> : <EditIcon />}
          </IconButtonWithTooltip>
        ) : (
          <>
            <IconButtonWithTooltip
              component={Link}
              role="link"
              data-testid="docIcon"
              title="See documentation"
              to={complementaryInformationLink}
            >
              <DescriptionIcon />
            </IconButtonWithTooltip>
            <IconButtonWithTooltip
              component={Link}
              role="link"
              disabled={!complementarySystemInformation}
              title="Edit complementary information"
              disabledTitle={`No complementary information for ${system.metadata.title}. See documentation.`}
              to={
                complementarySystemInformation?.metadata.annotations?.[
                  ANNOTATION_EDIT_URL
                ] ?? '#'
              }
            >
              <EditIcon />
            </IconButtonWithTooltip>
          </>
        ),
      }}
      deepLink={
        complementarySystemInformation?.metadata.offering
          ? {
              title: 'I have a request',
              link: `${complementarySystemInformation.metadata.offering}`,
            }
          : undefined
      }
      cardClassName={cardClass}
      variant="gridItem"
      noPadding
    >
      <CardContent
        className={`${
          aboutOrShortcutsTab.includes('Shortcuts')
            ? cardContentClassPadding
            : cardContentClass
        }`}
      >
        {editing ? (
          <EditSystemForm
            systemName={system.metadata.title!}
            systemId={system.metadata.name}
            show={editing}
            onClose={() => setEditing(false)}
          />
        ) : (
          <CustomSystemContentToDisplay
            contentToDisplay={aboutOrShortcutsTab}
            system={system}
          />
        )}
      </CardContent>
    </InfoCard>
  );
}
